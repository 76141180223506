import React from "react";
import trash from "../../images/trash.svg";

const UsersTable = (props) => {
    return(
        <>
            <div className='bg-gray-200 px-4 py-2 w-2/5 rounded h-fit'>
                <h1 className='text-center mb-4 text-xl uppercase font-bold'>Пользователи</h1>
                <table className='my-table'>
                    <thead>
                    <tr className='border-b-2 border-gray-400'>
                        <th>ID</th>
                        <th>LOGIN</th>
                        <th>ГРУППА</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.users?.map((user) => (
                        <tr key={user.id} className='text-center border-b-2 border-gray-300'>
                            <td>{user.id}</td>
                            <td>{user.login}</td>
                            <td>{user.Group.name}</td>
                            <td onClick={() => props.deleteUser(user)}>
                                <img src={trash} width={30} className='cursor-pointer border border-black rounded p-1'/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default UsersTable;