import React, {useEffect, useState, useRef} from "react";
import trash from "../../images/trash.svg"

const AddIncome = (props) => {

	const [candidat, setCandidat] = useState({name: ''});
	const [percent, setPercent] = useState(7.5);
	const [searchTerm, setSearchTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
	const [filteredManagers, setFilteredManagers] = useState(props.managers);
	const dropdownRef = useRef(null);
	
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		props.setFormData(prevState => ({
			...prevState,
			[name]: value
		}));
	};

    const addManagerToList = () => {
		if(candidat.name && candidat.id && percent){
			if(searchTerm !== candidat.name){
				alert('Выберите менеджера из выпадающего списка');
			}else if(props.managersList.some(manager => candidat.id === manager.id)){
				alert('Менеджер с таким именем уже добавлен');
			}else{
				props.setManagersList(prevManagersList => [
					...prevManagersList,
					{ id: candidat.id, name: candidat.name, percent, groupID: candidat.groupID}
				]);
				setCandidat({name: ''});
				setSearchTerm('');
			}
		}else{
			alert('Заполните пустые поля');
		}
	}

	const deleteManagerFromList = (managerToDelete) => {
		props.setManagersList(prevManagersList =>
			prevManagersList.filter(manager => manager !== managerToDelete)
		);
	}

	useEffect(() => {
		setFilteredManagers(props.managers?.filter(manager => 
			manager.deletedAt === null && manager.name.toLowerCase().includes(searchTerm.toLowerCase())
		));
	}, [searchTerm, props.managers])

	useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return(
        <div className='mx-auto bg-gray-200 px-4 py-2 w-2/3 rounded'>
			<h1 className='text-center mb-4 text-xl uppercase font-bold'>Добавить результат</h1>
			<div className='grid grid-cols-4 gap-y-2 justify-items-center custom-table'>
				<label>ДАТА</label>
				<label>СУММА</label>
				<label>МЕНЕДЖЕРЫ</label>
				<label>КОМЕНТАРИЙ</label>
				<input type="date" name="date" value={props.formData.date} onChange={handleInputChange} className="h-fit"/>
				<div className='mr-4'>
				<input type="number" name="sum" value={props.formData.sum} onChange={handleInputChange} placeholder="Sum" className='w-[100px] mr-2'/>
				<select name="exchange" defaultValue='USDT' value={props.formData.exchange} onChange={handleInputChange}>
					<option value="USDT">USDT</option>
					<option value="$">$</option>
				</select>
				</div>
				<div>
					<div className="flex">
						{/*<select value={candidat?.name} onChange={(e) => setCandidat({name: e.target.value, id: e.target.selectedOptions[0].getAttribute('id'), groupID: e.target.selectedOptions[0].getAttribute('groupID')})}>
							<option value=''>None</option>
							{props.managers?.filter(manager => manager.deletedAt === null).map((manager, index) => (
                            <option key={index} value={manager.name} id={manager.id} groupID={manager.groupID}>{manager.name}</option>
                        ))}
						</select>*/}
						<div ref={dropdownRef}>
							<input
								type="text"
								placeholder="Поиск менеджера..."
								value={searchTerm}
								onChange={(e) => {
									setSearchTerm(e.target.value);
									setShowDropdown(true);
								}}
								onClick={() => setShowDropdown(true)}
								className="w-full"
							/>
							{showDropdown && filteredManagers.length > 0 && (
							<ul className="dropdown absolute bg-white rounded px-2 py-1 mt-1">
								{filteredManagers.map((manager) => (
									<li 
										key={manager.id}
										onClick={() => {
											// Выбор менеджера из списка
											setCandidat({ 
												name: manager.name, 
												id: manager.id, 
												groupID: manager.groupID 
											});
											setSearchTerm(manager.name);
											setShowDropdown(false); // Закрыть список после выбора
											
										}}
										className="py-1 cursor-pointer"
									>
										{manager.name}
									</li>
								))}
							</ul>
						)}
						</div>
						<input type="number" value={percent} onChange={(e) => setPercent(e.target.value)} placeholder="Percent" className='w-[50px] ml-2 mr-4 '/>
					</div>
					{props.managersList?.map((manager, index) => (
						<span key={index} className='flex items-center mt-2'>{manager.name} - {manager.percent}% <img className='ml-2 cursor-pointer' src={trash} onClick={() => deleteManagerFromList(manager)}/></span>
					))}
					<button className='border border-black px-2 rounded mt-2 hover:bg-white' onClick={addManagerToList}>+</button>
				</div>
				<input type="text" name="comment" value={props.formData.comment} onChange={handleInputChange} placeholder="Comment" className='h-fit' />
			</div>
			<div className='flex justify-end'>
				<button className='uppercase font-bold px-4 py-2 border border-black rounded hover:bg-black hover:text-white' onClick={props.handleAddIncome}>Добавить</button>
			</div>
		</div>
    )
}

export default AddIncome;