import React, { useState, useContext } from "react";
import { AuthContext } from '.././utils/AuthProvider';
import { useNavigate } from "react-router-dom";
import $api from "../http";

const Auth = () => {

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const { setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const authUser = async () => {
        try{
            if(login && password){
                const response = await $api.post('/users/auth', {login, password});
                    setUser({login: response.data.login, group: response.data.group, groupID: response.data.groupID});
                    localStorage.setItem('token', response.data.token);
                    navigate('/');
            }else{
                alert('Заполните пустые поля');
            }
        }catch(err){
            alert(err.response.data.message);
        }
    }

    return (
        <>
            <div className='w-full flex items-center justify-center min-h-screen'>
                <div className='bg-gray-200 py-8 px-16 rounded'>
                    <h1 className='text-center font-bold text-xl'>Crypto CRM</h1>
                    <div className='flex flex-col mt-8'>
                        <label>Login</label>
                        <input className='px-1 rounded' value={login} onChange={(e) => setLogin(e.target.value)}/>
                        <label className='mt-4'>Password</label>
                        <input type='password' className='px-1 rounded' value={password} onChange={(e) => setPassword(e.target.value)}/>
                        <button className='mt-8 w-full py-2 border border-black rounded font-bold uppercase hover:bg-black hover:text-white' onClick={authUser}>Войти</button>
                    </div>
                </div>
            </div>
        </>
    );
  };
  
export default Auth;