import React from "react";

const AddGroup = (props) => {

    return(
        <>
            <div className='bg-gray-200 px-4 py-2 w-1/4 rounded h-fit'>
                <h1 className='text-center mb-4 text-xl uppercase font-bold'>Добавить группу</h1>
                <div className='flex custom-table items-center justify-around'>
                    <input className='w-1/2 h-fit' placeholder='NAME' value={props.groupName} onChange={(e) => props.setGroupName(e.target.value)}/>
                    <button className='uppercase font-bold px-4 py-2 border border-black rounded hover:bg-black hover:text-white' onClick={props.addGroup}>Добавить</button>
                </div>
            </div>
        </>
    )
}

export default AddGroup;