import React, { useState } from "react";

const AddUser = (props) => {

    const [login, setLogin] = useState();
    const [password, setPassword] = useState();
    const [groupID, setGroupID] = useState();

    const addUser = () => {
        if(login && password && groupID){
            props.addUser(login, password, groupID);
            setLogin('');
            setPassword('');
        }else{
            alert('Заполните пустые поля');
        }
    }

    return(
        <>
            <div className='bg-gray-200 px-4 py-2 w-2/5 rounded'>
                <h1 className='text-center mb-4 text-xl uppercase font-bold'>Добавить пользователя</h1>
                <table className='my-table'>
                    <thead>
                    <tr className='border-b-2 border-gray-400'>
                        <th>LOGIN</th>
                        <th>PASSWORD</th>
                        <th>ГРУППА</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className='border-b-2 border-gray-300 custom-table last:border-none'>
                        <td><input placeholder='LOGIN' value={login} onChange={(e) => setLogin(e.target.value)} /></td>
                        <td><input placeholder='PASSWORD' value={password} onChange={(e) => setPassword(e.target.value)} /></td>
                        <td>
                            <select onChange={(e) => setGroupID(e.target.value)}>
                            <option value=''>None</option>
                                {props.groups?.map((group, index) => (
                                    <option key={index} value={group.id}>{group.name}</option>
                                ))}
				            </select>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className='mt-2 float-end'>
                    <button className='uppercase font-bold px-4 py-2 border border-black rounded hover:bg-black hover:text-white' onClick={addUser}>Добавить</button>
                </div>
            </div>
        </>
    )
}

export default AddUser;