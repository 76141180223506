import React, { useState, useContext } from "react";
import trash from "../../images/trash.svg";
import pencil from "../../images/pencil.svg";
import confirm from "../../images/confirm.svg";
import cancel from "../../images/cancel.svg";
import { AuthContext } from "../../utils/AuthProvider";

const ManagersTable = (props) => {

    const { user, setUser } = useContext(AuthContext);

    const [editMode, setEditMode] = useState(0);
    const [newName, setNewName] = useState('');

    const editManagerName = () => {
        if(newName){
            props.editManager(editMode, newName);
            setEditMode(0);
            setNewName('');
        }else{
            alert('Измените имя или отмените редактирование');
        }
    }

    return(
        <>
            <div className='bg-gray-200 px-4 py-2 w-4/5 rounded h-fit mt-8'>
                <h1 className='text-center mb-4 text-xl uppercase font-bold'>Мои менеджеры</h1>
                <table className='my-table'>
                    <thead>
                    <tr className='border-b-2 border-gray-400'>
                        <th>ID</th>
                        <th>ИМЯ</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.managers?.filter(manager => manager.deletedAt === null).map((manager, index) => (
                        <>
                        <tr key={manager.id} className={`text-center border-b-2 border-gray-300 ${editMode !== manager.id ? '' : 'hidden'}`}>
                            <td>{manager.id}</td>
                            <td>{manager.name}</td>
                            <td className='flex justify-evenly'>
                                <img src={pencil} width={30} onClick={() => {setEditMode(manager.id); setNewName(manager.name);}} className='cursor-pointer border border-black rounded p-1'/>
                                <img src={trash} width={30} onClick={() => props.deleteManager(manager)} className='cursor-pointer border border-black rounded p-1'/>
                            </td>
                        </tr>
                        <tr key={`${manager.id}_edit`} className={`text-center border-b-2 border-gray-300 custom-table ${editMode === manager.id ? '' : 'hidden'}`}>
                            <td>{manager.id}</td>
                            <td><input value={newName} onChange={(e) => setNewName(e.target.value)} className='text-center w-4/5'/></td>
                            <td className='flex justify-evenly'>
                                <img src={confirm} width={30} onClick={editManagerName} className='cursor-pointer border border-black rounded'/>
                                <img src={cancel} width={30} onClick={() => setEditMode(0)} className='cursor-pointer border border-black rounded'/>
                            </td>
                        </tr>
                        </>                        
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default ManagersTable;