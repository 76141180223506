import React, { useState, useEffect, useContext } from "react";
import trash from "../../images/trash.svg";
import WeekSelect from "../WeekSelect";
import { AuthContext } from "../../utils/AuthProvider";

const IncomesTable = (props) => {

	const [totalTeamSum, setTotalTeamSum] = useState(0);
    const [totalManagerSum, setTotalManagerSum] = useState(0);
    const {user} = useContext(AuthContext);

	// useEffect(() => {
    //     const calculateTotalSum = () => {
    //         return props.incomes.reduce((total, item) => {

    //             const uniqueGroups = new Set(item.Managers.map(manager => manager.groupID));
    
    //             const share = item.sum / uniqueGroups.size;

    //             console.log(item.Managers);

    //             return total + share;
    //         }, 0);
    //     };
    
    //     setTotalSum(calculateTotalSum());
    // }, [props.incomes]);

    useEffect(() => {
        const calculateTotals = () => {
            let totalTeamSum = 0;
            let totalManagerSum = 0;
    
            props.incomes.forEach(item => {
                if (Array.isArray(item.Managers) && item.Managers.length > 0) {
                    const uniqueGroups = new Set(item.Managers.map(manager => manager.groupID));
                    const share = item.sum / uniqueGroups.size;
                    totalTeamSum += share;
    
                    item.Managers.forEach(manager => {
                        if (manager.groupID === user.groupID) {
                            const managerShare = item.sum * (manager.ManagerPercent.percent / 100);
                            totalManagerSum += managerShare;
                        }
                    });
                }
            });
    
            return { totalTeamSum, totalManagerSum };
        };
    
        const { totalTeamSum, totalManagerSum } = calculateTotals();
        setTotalTeamSum(totalTeamSum);
        setTotalManagerSum(totalManagerSum);
    }, [props.incomes]);

    return(
        <div className='mx-auto bg-gray-200 px-4 py-2 w-2/3 rounded mt-8'>
			<h1 className='text-center mb-4 text-xl uppercase font-bold'>Последние результаты</h1>
			<div className='custom-table mb-4'>
				<WeekSelect inputs={props.datePeriod} setInputs={props.setDatePeriod}/>
			</div>
			<table className='my-table'>
                <thead>
                <tr className='border-b-2 border-gray-400'>
                    <th>ДАТА</th>
                    <th>СУММА</th>
                    <th>МЕНЕДЖЕРЫ</th>
                    <th>ЗАРПЛАТА</th>
                    <th>КОМЕНТАРИЙ</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
				{props.incomes?.map((income, index) => {
                    const managers = income.Managers;

                    return (
                        <tr key={index} className='text-center border-b-2 border-gray-300 last:border-none'>
                            <td>{income.date}</td>
                            <td>{income.sum} {income.exchange}</td>
                            <td>
                                {managers.map((manager, managerIndex) => (
                                    <span key={managerIndex} className='block'>{manager.name} - {manager.ManagerPercent.percent}%</span>
                                ))}
                            </td>
                            <td>
                                {managers.map((manager, managerIndex) => (
                                    <span key={managerIndex} className='block'>{((income.sum / 100) * manager.ManagerPercent.percent).toFixed(0)} $</span>
                                ))}
                            </td>
                            <td>{income.comment}</td>
                            <td>
                                <img src={trash} width={30} className='cursor-pointer border border-black rounded p-1' onClick={() => props.deleteIncome(income)}/>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
			</table>
			<div className='border-t-2 border-gray-400 pt-2 flex flex-col items-end'>
				<span><b>Сумма:</b> {(totalTeamSum).toFixed(0)} $</span>
				<span><b>Зарплатный фонд:</b> {(totalTeamSum/10 + totalManagerSum).toFixed(0)} $</span>
				<span><b>Руководители:</b> {(totalTeamSum/10).toFixed(0)} $</span>
				<span><b>Менеджеры:</b> {totalManagerSum.toFixed(0)} $</span>
			</div>
		</div>
    )
}

export default IncomesTable;