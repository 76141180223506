import React from "react";

const AddExpense = (props) => {

    const handleInputChange = (e) => {
		const { name, value } = e.target;
		props.setFormData(prevState => ({
			...prevState,
			[name]: value
		}));
	};

    return(
        <div className='mx-auto bg-gray-200 px-4 py-2 w-fit rounded h-fit'>
            <h1 className='text-center mb-4 text-xl uppercase font-bold'>Добавить расход</h1>
            <div className='grid grid-cols-3 gap-y-2 justify-items-center custom-table'>
                <label>ДАТА</label>
                <label>СУММА</label>
                <label>КАТЕГОРИЯ</label>
                <input type="date" className="h-fit" name="date" value={props.formData.date} onChange={handleInputChange}/>
                <div>
                    <input type="number" placeholder="Sum" className='w-[100px] mr-2' name="sum" value={props.formData.sum} onChange={handleInputChange}/>
                    <select name="exchange" value={props.formData.exchange} onChange={handleInputChange}>
                        <option value="">Валюта</option>
                        <option value="USDT">USDT</option>
                        <option value="$">$</option>
                    </select>
                </div>
                <select name="categoryID" value={props.formData.category} onChange={handleInputChange}>
                    <option value=''>None</option>
                    {props.categoryList?.map((category, index) => (
                        <option key={index} value={category.id}>{category.name}</option>
                    ))}
                </select>
            </div>
            <div className='flex justify-end mt-4'>
                <button className='uppercase font-bold px-4 py-2 border border-black rounded hover:bg-black hover:text-white' onClick={props.addExpense}>Добавить</button>
            </div>
        </div>
    )
}

export default AddExpense;