import React from 'react';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import PrivateRoute from './utils/PrivateRoute';
import { AuthProvider } from './utils/AuthProvider';
import Auth from "./pages/Auth";
import Dashboard from "./pages/Dashboard";
import Income from "./pages/Income";
import Expenses from './pages/Expenses';
import Users from './pages/Users';
import Managers from './pages/Managers';
import Report from './pages/Report';

const App = () => {

  return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path="/auth" element={<Auth />}/>
                    <Route path="*" element={<Navigate to="/" replace />} />
                    <Route element={<PrivateRoute />}>
                        <Route path="/" element={<Dashboard />}/>
                        <Route path="/users" element={<Users />}/>
                        <Route path="/managers" element={<Managers />}/>
                        <Route path="/income" element={<Income />}/>
                        <Route path="/expenses" element={<Expenses />}/>
                        <Route path="/report" element={<Report />}/>
                    </Route>
                </Routes>
            </AuthProvider>
        </BrowserRouter>
  );
};

export default App;
