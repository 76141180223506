import React, { useState, useEffect } from "react";
import trash from "../../images/trash.svg";
import WeekSelect from "../WeekSelect";

const ExpensesTable = (props) => {

    const [totalExpenses, setTotalExpenses] = useState({
        USDT: 0,
        USD: 0
    });

    function calculateTotalExpenses(expenses, exchange) {
        return expenses.reduce((total, expense) => {
            if (expense.exchange === exchange) {
                return total + expense.sum;
            }
            return total;
        }, 0);
    }

    useEffect(() => {
        setTotalExpenses(prevState => ({
			...prevState,
			'USDT': calculateTotalExpenses(props.expenses, 'USDT'),
            'USD': calculateTotalExpenses(props.expenses, '$')
		}));
    }, [props.expenses]);

    return(
        <div className='bg-gray-200 px-4 py-2 rounded mt-8'>
                <h1 className='text-center mb-4 text-xl uppercase font-bold'>Последние расходы</h1>
                <div className='custom-table mb-4'>
                    <WeekSelect inputs={props.datePeriod} setInputs={props.setDatePeriod}/>
			    </div>
                <table className='my-table'>
                    <thead>
                    <tr className='border-b-2 border-gray-400'>
                        <th>ДАТА</th>
                        <th>СУММА</th>
                        <th>КАТЕГОРИЯ</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.expenses?.map((expense, index) => (
                        <tr key={index} className='text-center border-b-2 border-gray-300 last:border-none'>
                            <td>{expense.date}</td>
                            <td>{expense.sum} {expense.exchange}</td>
                            <td>{expense.ExpensesCat.name}</td>
                            <td>
                                <img src={trash} width={30} className='cursor-pointer border border-black rounded p-1' onClick={() => props.deleteExpense(expense)}/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div className='border-t-2 border-gray-400 pt-2 flex flex-col items-end'>
                    <span><b>Расходы:</b></span>
                    <span>{(totalExpenses.USDT).toFixed(0)} <b>USDT</b></span>
                    <span>{(totalExpenses.USD).toFixed(0)} <b>USD</b></span>
                    <span><b>Всего:</b>{(totalExpenses.USDT + totalExpenses.USD).toFixed(0)}</span>
                </div>
            </div>
    )
}

export default ExpensesTable;