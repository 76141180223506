import React, { useEffect, useState, useContext } from "react";
import $api from "../../http";
import WeekSelect from "../WeekSelect";
import { AuthContext } from "../../utils/AuthProvider";

const ManagersSalary = (props) => {

    const { user, setUser } = useContext(AuthContext);

    const [datePeriod, setDatePeriod] = useState([]);

    const [incomes, setIncomes] = useState([]);
    const [managerEarnings, setManagerEarnings] = useState({});
    const [totalSum, setTotalSum] = useState(0);

    const getIncomes = async () => {
        try {
            const response = await $api.get('/income/get', {
                params: {
                    dateRanges: datePeriod.dateRanges
                }
            });
            setIncomes(response.data);
        } catch (error) {
            console.log(error.response.data.message);
        }
    }

    useEffect(() => {
        if(datePeriod.dateRanges?.length > 0){
            getIncomes();
        }
    }, [datePeriod]);

    useEffect(() => {
        setManagerEarnings(calculateManagerEarnings(incomes));
        //setTotalSum(incomes.reduce((sum, item) => sum + item.sum, 0));
    }, [incomes]);

    // function calculateManagerEarnings(income) {
    //     const earnings = {};
      
    //     income.forEach(item => {
    //         const managers = item.Managers;
    //         const itemSum = item.sum;
        
    //         managers.forEach(manager => {
    //             const percent = parseFloat(manager.ManagerPercent.percent);
    //             const earningsForManager = (itemSum * percent) / 100;
        
    //             if (!earnings[manager.id]) {
    //             earnings[manager.id] = 0;
    //             }
        
    //             earnings[manager.id] += earningsForManager;
    //         });
    //     });
      
    //     return earnings;
    // }

    function calculateManagerEarnings(income) {
        const earnings = {};
        let totalTeamSum = 0;
    
        income.forEach(item => {
            const managers = item.Managers;
            const itemSum = item.sum;
    
            const uniqueGroups = new Set(managers.map(manager => manager.groupID));
            const share = itemSum / uniqueGroups.size;
            totalTeamSum += share;
    
            managers.forEach(manager => {
                const percent = parseFloat(manager.ManagerPercent.percent);
                const earningsForManager = (itemSum * percent) / 100;
    
                if (!earnings[manager.id]) {
                    earnings[manager.id] = 0;
                }
    
                earnings[manager.id] += earningsForManager;
            });
        });


        setTotalSum(totalTeamSum);
        return earnings;
    }

    const findManagerById = (id) => {
        const manager = props.managers.find(manager => manager.id === id);
        return manager?.name;
    };

    return(
        <>
            <div className='bg-gray-200 px-4 py-2 w-1/4 rounded h-fit'>
                <div className='custom-table'>
                    <WeekSelect inputs={datePeriod} setInputs={setDatePeriod}/>
                </div>
                <h1 className='text-center my-4 text-xl uppercase font-bold'>Зарплата за период</h1>
                <table className='my-table'>
                    <thead>
                    <tr className='border-b-2 border-gray-400'>
                        <th>МЕНЕДЖЕР</th>
                        <th>ЗАРПЛАТА</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className='text-center border-b-2 border-gray-300'>
                        <td>Руководители</td>
                        <td>{(totalSum/10).toFixed(0)} $</td>
                    </tr>
                    {Object.keys(managerEarnings).map(key => {
                        const managerName = findManagerById(parseInt(key));
                        return managerName !== undefined ? (
                            <tr key={key} className='text-center border-b-2 border-gray-300'>
                                <td>{managerName}</td>
                                <td>{(managerEarnings[key]).toFixed(0)} $</td>
                            </tr>
                        ) : null;
                    })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default ManagersSalary;