import React, { useEffect, useState, useContext } from "react";
import Header from "../components/Header";
import AddManager from "../components/Managers/AddManager";
import ManagersTable from "../components/Managers/ManagersTable";
import $api from "../http/index";
import { AuthContext } from "../utils/AuthProvider";
import { useNavigate } from "react-router-dom";
import ManagersSalary from "../components/Managers/ManagersSalary";

const Managers = () => {

    const [managers, setManagers] = useState([]);
    const { user, setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const getManagers = async () => {
        try{
            const response = await $api.get('/managers/get');
            setManagers(response.data);
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const addManager = async (name, group) => {
        try{
            await $api.post('/managers/add', {name, group});
            getManagers();
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const deleteManager = async (manager) => {
        try {
            await $api.delete(`/managers/delete/${manager.id}`);
            getManagers();
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const editManager = async(managerId, newName) => {
        const findManager = managers.filter((manager) => manager.id === managerId);
        if(findManager[0].name !== newName){
            try{
                await $api.patch(`/managers/editName/${managerId}`, {newName});
                getManagers();
            }catch(error){
                alert(error.response.data.message);
            }
        }
    }

    useEffect(() => {
        if(user.group === 'Администратор'){
            navigate('/');
        }
        getManagers();
    }, []);

    return(
        <>
            <Header active='managers'/>
            <div className='flex mx-auto justify-evenly mb-20'>
                <div className='flex flex-col-reverse items-center'>
                    <ManagersTable managers={managers} deleteManager={deleteManager} editManager={editManager} />
                    <AddManager addManager={addManager} />
                </div>
                <ManagersSalary managers={managers} />
            </div>
        </>
    )
}

export default Managers;