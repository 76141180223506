import React, { useEffect, useState, useRef } from 'react';
import { startOfWeek, endOfWeek, subWeeks, format } from 'date-fns';

const WeekSelect = (props) => {

    const [selectMenu, setSelectMenu] = useState(false);
    const [selectedDate, setSelectedDate] = useState([]);
    const wrapperRef = useRef(null);

    const currentDate = new Date();
    const startDateOfThisWeek = startOfWeek(currentDate, { weekStartsOn: 1 });
    const endDateOfThisWeek = endOfWeek(currentDate, { weekStartsOn: 1 });

    const thisWeek = {
        startDate: startDateOfThisWeek,
        endDate: endDateOfThisWeek
    };

    const previousWeeks = Array.from({ length: 10 }, (_, index) => ({
        startDate: startOfWeek(subWeeks(currentDate, index + 1), { weekStartsOn: 1 }),
        endDate: endOfWeek(subWeeks(currentDate, index + 1), { weekStartsOn: 1 })
    }));

    const formattedOptions = previousWeeks.map((week) => ({
        label: `${format(week.startDate, 'dd-MM-yyyy')} - ${format(week.endDate, 'dd-MM-yyyy')}`,
        value: `${format(week.startDate, 'yyyy-MM-dd')},${format(week.endDate, 'yyyy-MM-dd')}`
    }));

    function sortByKey(array) {
        return array.sort((a, b) => {
            if (a.start < b.start) {
                return -1;
            }
            if (a.start > b.start) {
                return 1;
            }
            return 0;
        });
    }

    useEffect(() => {
        setSelectedDate([{start: format(thisWeek.startDate, 'yyyy-MM-dd'), stop: format(thisWeek.endDate, 'yyyy-MM-dd')}])
    }, [])

    useEffect(() => {
        props.setInputs(prevState => ({
            ...prevState,
            dateRanges: sortByKey(selectedDate)
        }));
    }, [selectedDate]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setSelectMenu(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    const selectDate = (event) => {
        const selectedDates = event.target.value.split(',');
        const newStart = selectedDates[0];
        const newStop = selectedDates[1];
        const existingIndex = selectedDate.findIndex(item => item.start === newStart && item.stop === newStop);
        
        if(existingIndex !== -1){
            setSelectedDate(prevState => {
                const updatedArray = [...prevState];
                updatedArray.splice(existingIndex, 1);
                return sortByKey(updatedArray); // Сортировка после удаления элемента
            });
        }else{
            setSelectedDate(prevState => {
                const updatedArray = [
                    ...prevState,
                    { start: newStart, stop: newStop }
                ];
                return sortByKey(updatedArray);
            });
        }
    }

    return (
        <>
            <button onClick={() => setSelectMenu(!selectMenu)} className='bg-white rounded px-2 py-1 btn-open'>
                {selectedDate.length > 0 ? format(selectedDate[0]?.start, 'dd-MM-yyyy') + ' - ' + format(selectedDate[selectedDate.length-1]?.stop, 'dd-MM-yyyy') : 'Выберите дату'}
            </button>
            <div className={`absolute bg-white rounded p-2 border z-10 ${selectMenu ? '' : 'hidden'}`} ref={wrapperRef}>
                <div>
                    <input type='checkbox' defaultChecked={true} value={`${format(thisWeek.startDate, 'yyyy-MM-dd')},${format(thisWeek.endDate, 'yyyy-MM-dd')}`} onClick={selectDate}/>
                    <span className='ml-2'>{`${format(thisWeek.startDate, 'dd-MM-yyyy')} - ${format(thisWeek.endDate, 'dd-MM-yyyy')}`}</span>
                </div>
                {formattedOptions.map(option => (
                    <div key={option.value}>
                        <input type='checkbox' value={option.value} onClick={selectDate}/>
                        <span className='ml-2'>{option.label}</span>
                    </div>
                ))}
            </div>
        </>
    );
}

export default WeekSelect;