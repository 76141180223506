import React, { useState } from "react";
import trash from "../../images/trash.svg";

const ExpensesCategory = (props) => {

    return(
        <div className='w-fit mx-auto mb-20'>
            <div className='bg-gray-200 px-4 py-2 rounded h-fit'>
                <h1 className='text-center mb-4 text-xl uppercase font-bold'>Добавить категорию расходов</h1>
                <div className='flex custom-table items-center justify-around'>
                    <input className='w-1/2 h-fit' placeholder='NAME' value={props.categoryInput} onChange={(e) => props.setCategoryInput(e.target.value)}/>
                    <button className='uppercase font-bold px-4 py-2 border border-black rounded hover:bg-black hover:text-white' onClick={props.addCategory}>Добавить</button>
                </div>
            </div>
            <div className='bg-gray-200 px-4 py-2 rounded mt-8'>
                <h1 className='text-center mb-4 text-xl uppercase font-bold'>Категории расходов</h1>
                <table className='my-table'>
                    <thead>
                    <tr className='border-b-2 border-gray-400'>
                        <th>ID</th>
                        <th>НАЗВАНИЕ</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.categoryList?.map((category) => (
                        <tr key={category.id} className='text-center border-b-2 border-gray-300'>
                            <td>{category.id}</td>
                            <td>{category.name}</td>
                            <td onClick={() => props.deleteCategory(category)}>
                                <img src={trash} width={30} className='cursor-pointer border border-black rounded p-1'/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ExpensesCategory;