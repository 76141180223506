import React from "react";
import trash from "../../images/trash.svg";

const GroupsTable = (props) => {

    return(
        <>
            <div className='bg-gray-200 px-4 py-2 w-1/4 rounded h-fit'>
                <h1 className='text-center mb-4 text-xl uppercase font-bold'>Группы</h1>
                <table className='my-table'>
                    <thead>
                    <tr className='border-b-2 border-gray-400'>
                        <th>ID</th>
                        <th>НАЗВАНИЕ</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.groups?.map((group) => (
                        <tr key={group.id} className='text-center border-b-2 border-gray-300'>
                            <td>{group.id}</td>
                            <td>{group.name}</td>
                            <td onClick={() => props.deleteGroup(group)}>
                                <img src={trash} width={30} className='cursor-pointer border border-black rounded p-1'/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default GroupsTable;