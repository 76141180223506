import React from "react";
import Header from "../components/Header";

const Dashboard = () => {

    return (
        <>
          <Header active='dashboard'/>
        </>
    );
  };
  
export default Dashboard;