import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../utils/AuthProvider";

const Header = (props) => {

    const [menuActive, setMenuActive] = useState(false);
    const { user, setUser } = useContext(AuthContext);

    const logout = () => {
        setUser(null);
        localStorage.removeItem('token');
    }

    return (
        <>
            <div className={`min-h-screen w-1/5 bg-gray-200 absolute pt-4 px-4 ${menuActive ? '' : 'hidden'}`}>
                <div className='flex justify-between items-center'>
                    <h1 className='text-xl'>CryptoCRM</h1>
                    <div className='cursor-pointer' onClick={() => setMenuActive(false)}>
                        <span className='block w-8 h-1 bg-black rounded rotate-45 mt-1'></span>
                        <span className='block w-8 h-1 bg-black rounded -rotate-45 -mt-1'></span>
                    </div>
                </div>
                <div className='mt-20 flex flex-col text-xl'>
                    <Link to="/" className={props.active === 'dashboard' ? 'font-bold' : '' }>Главная</Link>
                    <Link to="/users" className={`mt-2 ${props.active === 'users' ? 'font-bold' : ''} ${user.group !== 'Администратор' ? 'hidden' : ''}`}>Пользователи</Link>
                    <Link to="/managers" className={`mt-2 ${props.active === 'managers' ? 'font-bold' : ''} ${user.group === 'Администратор' ? 'hidden' : ''}`}>Менеджеры</Link>
                    <Link to="/income" className={`mt-2 ${props.active === 'income' ? 'font-bold' : ''} ${user.group === 'Администратор' ? 'hidden' : ''}`}>Доходы</Link>
                    <Link to="/expenses" className={`mt-2 ${props.active === 'expenses' ? 'font-bold' : ''}`}>Расходы</Link>
                    <Link to="/report" className={`mt-2 ${props.active === 'report' ? 'font-bold' : ''} ${user.group !== 'Администратор' ? 'hidden' : ''}`}>Отчет</Link>
                </div>
            </div>
            <div className='px-8 py-4 flex justify-between items-center mb-8'>
                <div className='cursor-pointer' onClick={() => setMenuActive(true)}>
                    <span className='block w-8 h-1 bg-black rounded'></span>
                    <span className='block w-8 h-1 bg-black rounded mt-1'></span>
                    <span className='block w-8 h-1 bg-black rounded mt-1'></span>
                </div>
                <div>
                    {user ? <span className='mr-4'>Привет, {user.login}</span> : <></>}
                    <button className='py-2 px-4 border border-black rounded font-bold hover:bg-black hover:text-white' onClick={logout}>Выйти</button>
                </div>
            </div>
        </>
    );
  };
  
export default Header;